@import "../../../../sass/mixin/ColorsMixin.scss";

.MuiDrawer-root {
  .MuiPaper-root {
    color: $dark-color !important;
  }
}

.drawerPanel {
  .listItem {
    display: flex;
  }
}

@import "../../../sass/mixin/ColorsMixin.scss";

.AppFooter {
  color: $dark-color;
  height: fit-content;
  background-color: $light-color;
  box-shadow: $medium-color 0 0px 10px;
  text-align: left;
  font-size: smaller;

  .MuiSvgIcon-colorPrimary {
    color: $dark-color;
  }

  .MuiTypography-colorPrimary {
    color: $dark-color;
  }

  a {
    padding-left: 0.25em;
  }
}

.FooterBottom {
  text-align: center;
  height: 2em;
  width: 100%;
}

.FooterCredits {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
}

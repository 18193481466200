@import "../../sass/mixin/ColorsMixin.scss";
@import "../../sass/mixin/ResponsiveMixin.scss";

main {
  color: $light-color;
  background-color: $dark-color;

  .app-header {
    text-align: center;
  }

  .app-body {
    min-height: 82vh;
    padding-top: 3em;
    align-items: center;
    justify-content: center;

    @media (max-width: $md) {
      text-align: center;
    }

    .MuiPaper-root {
      color: $light-color !important;
      background-color: $dark-color !important;
    }

    .MuiTypography-body1 {
      font-size: 1.25em !important;
      font-weight: 400;
      line-height: 1.334;
      letter-spacing: 0em;

      @media (max-width: $md) {
        font-size: 1em !important;
      }
    }
  }
}

.left {
  text-align: left;
  justify-content: left;
}

.right {
  text-align: right;
  justify-content: right;
}

.topSpacing {
  margin-top: 0em !important;

  @media (max-width: $md) {
    margin-top: 2em !important;
  }
}

.bottomSpacing {
  margin-bottom: 2em !important;
}

@import "../../../sass/mixin/ResponsiveMixin.scss";
@import "../../../sass/mixin/ColorsMixin.scss";

#homePage {
  .presentation {
    margin-top: 1em;
  }
  .title {
    font-size: 2em;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    margin-bottom: 0.35em;

    @media (max-width: $md) {
      font-size: 1em;
    }
  }
  .icon {
    border: solid 2px $light-color;
    padding: 0.25em;
    border-radius: 25%;
    height: auto;
    width: 1em;
    min-width: 38px !important;
    &:hover {
      border-color: $medium-color;
    }
  }
  .MuiSvgIcon-colorPrimary {
    color: $light-color;
    &:hover {
      color: $medium-color;
    }
  }

  .MuiTypography-colorPrimary {
    color: $light-color;
  }

  .secondBloc {
    @media (min-width: $lg) {
      margin-top: 10em;
    }
  }
}

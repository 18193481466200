@import "../../../sass/mixin/ColorsMixin.scss";

.app-bar {
  .title {
    display: block;
  }

  .sectionDesktop {
    display: block;
  }

  .MuiAppBar-colorPrimary {
    background-color: white !important;
    color: $dark-color !important;
  }

  .MuiTypography-body1 {
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }
}

.grow {
  flex-grow: 1;
}
